<template>
    <div>
        
    </div>
</template>

<script>
export default {
    data() {
        return{}
    },
    created() {
        this.$router.push('/')
    }
}
</script>